import React, { useEffect, useContext, useState } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import GoogleFontLoader from "react-google-font-loader";

import App from "./views/App";

import Booking from "./views/Booking/booking";
import BookingSummary from "./views/BookingSummary/booking_summary";
import SearchBooking from "./views/SearchBooking/search_booking";
import MyBooking from "./views/MyBooking/my_booking";
import Register from "./views/Account/register";
import PasswordReset from "./views/Account/password_reset";
import ModifyBookingPage from "./views/Booking/modify_booking";
import SuccessConfirmationCode from "./views/SuccessConfirmationCode";
import ErrorConfirmationCode from "./views/ErrorConfirmationCode";

import { ThemeContext } from "./components/Wrapper";

//services
import { getAllHotels } from "./services/hotel";
import GeneralConditionsOfSale from "./views/GeneralConditionOfSale/GeneralConditionOfSale";
import LegalNotice from "./views/LegalNotice/LegalNotice";
import ClientVoucher from "./views/Parc/ClientVoucher";
import ClientReservation from "./views/Parc/ClientReservation";
import ReservationReportComponent from "./components/my-booking/Parc/ReservationReportComponenent";
import VerifyBooking from "./views/Booking/verify_booking";

const HotelRouter = () => {
  const { hotel } = useParams();
  const urlHotelName = hotel?.toLowerCase();
  const context = useContext(ThemeContext);
  useEffect(() => {
    // window.scrollTo({top: 0})
    localStorage.removeItem('hotel');
    let theme = {
      typography: {
        h1: "Arial",
        h2: "",
        h3: "",
      },
      btn: {
        primary: "green",
        secondary: "lightgreen",
      },
    };

    const hotelList = [
      {
        name: "ibis",
        address: "29 Rue Prince Ratsimamange ANTANANARIVO, Madagascar, 101",
        phoneNum: "261-020-2220202",
        link: "www.ibis-hotel.mg",
        photo: "logo.jpg",
        theme: {
          typography: {
            h1: "Montserrat",
            h2: "",
            h3: "",
          },
          btn: {
            primary: "#ff0000",
            secondary: "black",
          },
        },
      },
      {
        name: "colbert",
        address: "29 Rue Prince Ratsimamange ANTANANARIVO, Madagascar, 101",
        phoneNum: "261-020-5521212",
        link: "www.colbert.mg",
        photo: "colbert.png",
        theme: {
          typography: {
            h1: "Playfair Display",
            h2: "",
            h3: "",
          },
          btn: {
            primary: "#808080",
            secondary: "black",
          },
        },
      },
    ];

    // getting list of existing hotels
    const payload = {
      tableName: "hotel",
      valuesToSearch: [],
      fieldsToPrint: [
        "_id",
        "name",
        "phoneNum",
        "emailAddress",
        "link",
        "address",
        "checkIn",
        "checkOut",
        "vignette",
        "minKidAge",
        "maxKidAge",
        "minBabyAge",
        "maxBabyAge",
        "location",
        "isTVAIncluded",
        "TVA",
        "photo",
        "logo",
        "banner",
        "typography_h1",
        "typography_h2",
        "typography_h3",
        "primary_button_color",
        "secondary_button_color",
      ],
      nbContent: 200,
      numPage: 1,
    };
    context.showThemeLoader(true);
    getAllHotels(payload)
      .then((result) => {
        result.data.status = 200;
        if (result.data.status === 200) {
          const listTemp = JSON.parse(JSON.stringify(result.data.list));
          listTemp.forEach((el, i) => {
            delete el.typography_h1;
            delete el.typography_h2;
            delete el.typography_h3;
            delete el.primary_button_color;
            delete el.secondary_button_color;
            el.theme = {
              typography: {
                h1: result.data.list[i].typography_h1,
                h2: result.data.list[i].typography_h2,
                h3: result.data.list[i].typography_h3,
              },
              btn: {
                primary: result.data.list[i].primary_button_color,
                secondary: result.data.list[i].secondary_button_color,
              },
            };

            hotelList.push(el);

            //finding the actual hotel by url (Ex: /ibis)
            hotelList.forEach((e) => {
              if (
                hotel.toLowerCase().localeCompare(e.urlName, undefined, {
                  sensitivity: "accent",
                }) === 0
              ) {
                theme = e.theme;
                sessionStorage.setItem('hotel', JSON.stringify(e));
                context.setHotel(e);
              }
            });

            // changing value in style.css (By actual theme)
            const rootElement = document.querySelector(":root");
            const rootStyle = getComputedStyle(rootElement);
            rootElement.style.setProperty(
              "--btn-background",
              theme.btn.primary
            );
            rootElement.style.setProperty(
              "--btn-background-hover",
              theme.btn.secondary
            );
            rootElement.style.setProperty(
              "--h2-font-family",
              theme.typography.h1
            );
            rootElement.style.setProperty("--h1", theme.typography.h1);
            rootElement.style.setProperty("--h2", theme.typography.h2);
            rootElement.style.setProperty("--h3", theme.typography.h3);
          });
        } else {
          console.log("Hotels fetching errors......");
        }
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setTimeout(() => {
          context.showThemeLoader(false);
        }, 500);
      });
  }, []);
  return (
    <>
      <GoogleFontLoader
        fonts={[
          {
            font: context.getHotel().theme.typography.h1,
            weights: [400, 700],
          },
          {
            font: context.getHotel().theme.typography.h2,
            weights: [400, 700],
          },
          {
            font: context.getHotel().theme.typography.h3,
            weights: [400, 700],
          },
        ]}
        subsets={["cyrillic-ext", "greek"]}
      />
      <Routes>
        {/* VAOVAO */}
        <Route path="booking/:orderId" element={<VerifyBooking urlHotelName={urlHotelName} />}/>

        <Route path="booking/clientVoucher" element={<ClientVoucher urlHotelName={urlHotelName} />} />

        <Route path="booking/postpone" element={<ReservationReportComponent urlHotelName={urlHotelName} />} />

        <Route path="" element={<App urlHotelName={urlHotelName} />} />

        <Route path="booking" element={<Booking urlHotelName={urlHotelName} />}>
          <Route path=":cookies" element={<Booking />} />
        </Route>

        <Route path="password-reset" element={<PasswordReset />} />

        <Route path="booking-summary" element={<BookingSummary urlHotelName={urlHotelName} />}>
          <Route path=":bookingId" element={<BookingSummary />} />
        </Route>

        <Route path="search-booking" element={<SearchBooking urlHotelName={urlHotelName} />} />

        <Route path="my-booking" element={<MyBooking />}>
          <Route
            path=":searchBy/:email/:itineraryNumberOrPassword"
            element={<MyBooking />}
          />
        </Route>

        <Route path="register" element={<Register urlHotelName={urlHotelName} />} />

        <Route path="modify-booking" element={<ModifyBookingPage />}>
          <Route path=":bookingId" element={<ModifyBookingPage />} />
        </Route>

        <Route
          path="generalConditionOfSale"
          element={<GeneralConditionsOfSale />}
        />

        <Route
          path="legalNotice"
          element={<LegalNotice />}
        />

        <Route
          path="successConfirmationCode"
          element={<SuccessConfirmationCode />}
        />
        <Route path="errorConfirmationCode" element={<ErrorConfirmationCode />}>
          <Route path=":message" element={<ErrorConfirmationCode />} />
        </Route>
      </Routes>
    </>
  );
};

export default HotelRouter;
