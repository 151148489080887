import axios from "axios";

export const getDaysBetweenToDate = (date1, date2) => {
  const diffTime = date2 - date1; //milliseconds
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); //days
  return diffDays;
};

export const getDateMadagascar =  () => {
  return new Promise((resolve, reject) => {
    axios.get(
      `https://worldtimeapi.org/api/timezone/Indian/Antananarivo`
    )
    .then((result) => {
      // EXAMPLE OF RESULT
      // result.data = {
      //   "abbreviation": "EAT",
      //   "client_ip": "102.16.42.146",
      //   "datetime": "2022-11-15T10:47:11.869857+03:00",
      //   "day_of_week": 2,
      //   "day_of_year": 319,
      //   "dst": false,
      //   "dst_from": null,
      //   "dst_offset": 0,
      //   "dst_until": null,
      //   "raw_offset": 10800,
      //   "timezone": "Indian/Antananarivo",
      //   "unixtime": 1668498431,
      //   "utc_datetime": "2022-11-15T07:47:11.869857+00:00",
      //   "utc_offset": "+03:00",
      //   "week_number": 46
      // }
      const dateMadagascar = new Date(result.data.utc_datetime);
      // first get the date UTC
      dateMadagascar.setMinutes(
        dateMadagascar.getMinutes() 
        + dateMadagascar.getTimezoneOffset()
      );
      // then get the date for Madagascar: 180 minutes = 3 hours => UTC +03:00
      dateMadagascar.setMinutes(dateMadagascar.getMinutes() + 180);
      
      resolve(dateMadagascar);
    })
    .catch(err => {
      console.error("Première URL échouée, tentative avec une autre URL...", err);
       // Tentative avec une autre URL
       axios.get(`https://timeapi.io/api/time/current/zone?timeZone=indian/Antananarivo`)
        .then((result) => {
          const dateMadagascar = new Date(result.data.dateTime);
          resolve(dateMadagascar);
        })
        .catch((secondErr) => {
          console.error("Seconde URL échouée également.", secondErr);
          reject(secondErr); // Rejette si les deux échouent
        });
    });
  });
};
