import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeContext } from "../../components/Wrapper";
import { CheckPaiementBookingId } from "../../services/booking";

const VerifyBooking = ({urlHotelName}) => {
  const navigate = useNavigate();
  const params = useParams(); // Récupérer le paramètre de la route

  const CheckPaiement = async () => {
    console.log("booking",);
    await CheckPaiementBookingId(params.orderId)
      .then((results) => {
        console.log("data",results.data);
        if (results.data.notification.status =='SUCCESS') {
            navigate(
                `/${urlHotelName}/booking/clientVoucher`,
                {
                    state: {
                        reservationDetails: results.data.reservationDetails
                    }
                }
            );
        }
        else{
          console.log("---",results.data.notification);
          
          navigate(`/${urlHotelName}/booking`);
        }
      })
      .catch((error) => {
        navigate(`/${urlHotelName}`);
      });
  }

  useEffect(() => {
    CheckPaiement();
  }, [navigate, params]);
  console.log("params",params);
  
  return (
    <div>
      <h1>Verification en cours...</h1>
    </div>
  );
};

export default VerifyBooking;
